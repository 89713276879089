import * as React from "react"

import { Container } from "../../components"
import { Category } from "../../section"
import Seo from "../../components/seo"

const Lifestyle = () => (
  <Container>
    <Seo
      title="健康"
      description="健康的な食事や生活習慣は必ず見た目に現れます。たとえスキンケアやトレーニングをしっかりしていても食事や睡眠が上手に取れていないと全て台無しになります。ここではストレスの溜めない生活を送るためのノウハウや誰でも簡単に作れる肌に良い食事のレシピ・栄養の高い食材や商品を紹介します。"
    />
    <Category Title="Lifestyle" SubTitle="健康" theCategory="lifestyle" />
  </Container>
)

export default Lifestyle
